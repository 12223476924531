import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import Resume from "../subpages/resume"
import AboutSubnav from "../components/about_subnav"
import HeroBanner from "../components/herobanner"
import heroimage from "../assets/images/about_hero@2x.jpg"
import portrait from "../assets/images/about-portrait-yourstruly.jpg"
import LogoSVG from "../assets/svg/ltb_logotype_condensed.svg"
import AboutIntroCopy from "../copy/about_intro"
import AboutMeCopy from "../copy/about_me"

export default function About({data}) {
  return (
    <Layout className="about_page" pageTitle="About">
      <HeroBanner title="about hero" heroimage={{publicURL: heroimage}}>
        <h1 id="logo_condensed" className="smaller">
            <img src={LogoSVG} alt={data.site.siteMetadata.title}></img>
        </h1>
        <p>A creative digital hotshop with over 14 years of experience and an indie-games studio, all in one.</p>

      </HeroBanner>

      <AboutSubnav heroBanner></AboutSubnav>
      
      <article className="hasSidenav about_ltb">
        <AboutIntroCopy />
      </article>

      <div className="clearfix"></div>
      <hr/>

      <article className="about_ltb">
        <figure className="pull_left">
          <img src={portrait} alt="Yours truly" title="Yours truly" />
        </figure>
        
        <div className="pull_right">
          <AboutMeCopy />
        </div>
      </article>

      <div className="clearfix"></div>

      <Resume />
    </Layout>
  )
}

export const query = graphql`
query {
  site {
    siteMetadata {
      title
    }
  }
}
`