import React from "react"
import ResumeAwardsCopy from "../copy/resume_awards"
import ResumeEducationCopy from "../copy/resume_education"
import ResumeExperienceCopy from "../copy/resume_experience"
import ResumePhilosophyCopy from "../copy/resume_philosophy"
import ResumeReferencesCopy from "../copy/resume_references"
import ResumeSkillsCopy from "../copy/resume_skills"
import ResumeTechSkillsCopy from "../copy/resume_techskills"

export default function Resume(props){

    return(
        <article id="resume">
            <h1>Resumé</h1>

            <div className="col_1">
                <section id="philosophy">
                    <ResumePhilosophyCopy />
                </section>
                
                <section id="experience">
                    <ResumeExperienceCopy />
                </section>
                
                <section id="education">
                    <ResumeEducationCopy />
                </section>
                
                <section id="awards">
                    <ResumeAwardsCopy />
                </section>
                
                <section id="references">
                    <ResumeReferencesCopy />
                </section>
            </div>
            
            <div className="col_2">
                <section id="skills">
                    <ResumeSkillsCopy />
                </section>
                
                <section id="computerSkills">
                    <ResumeTechSkillsCopy />
                </section>
            </div>
        </article>
    )
}