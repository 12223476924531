import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

const ResumeExperienceCopy = () => (
  <StaticQuery
    query={graphql`
      {
        allMdx(filter: {slug: {eq: "content/resume_experience"}}) {
          edges {
            node {
              id
              slug
              body
            }
          }
        }
      }
    `}
    render={data => (
        <MDXRenderer>{data.allMdx.edges[0].node.body}</MDXRenderer>
    )}
  ></StaticQuery>
)

export default ResumeExperienceCopy

